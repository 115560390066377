import store from "@/store";
import FileSaver from "file-saver"
import * as XLSX from "xlsx"


let type = '';
// 行政区名称列表
let areaName = [];
// 行政区对象
let areaObject = {};
// 查询地址
let url = {
	"1": "environment/getEnvironment",
	"2": "environment/getRain",
	"3": "environment/getAirTemperature",
	"4": "environment/getSunshine",
	"5": "environment/getSoilNutrient",
	"6": "environment/getAgriculture",
	"7": "environment/soilHeavy",
	"21": "tobacco-quality/conventional",
	"22": "tobacco-quality/conditionAppearance",
	"23": "tobacco-quality/physical",
	"24": "tobacco-quality/sensory",
	"25": "tobacco-quality/pigment",
	"26": "tobacco-quality/organic",
	"27": "tobacco-quality/neutral"
};

let tableName = {
	"1": "植烟环境-地理环境",
	"2": "植烟环境-降雨量",
	"3": "植烟环境-气温",
	"4": "植烟环境-日照时数",
	"5": "植烟环境-土壤常规养分",
	"6": "植烟环境-土壤农残",
	"7": "植烟环境-土壤重金属",
	"21": "烟叶质量-常规化学成分",
	"22": "烟叶质量-外观品质",
	"23": "烟叶质量-物理特性",
	"24": "烟叶质量-感官评吸",
	"25": "烟叶质量-色素多酚",
	"26": "烟叶质量-有机酸",
	"27": "烟叶质量-中性致香成分"
};
export const dataUtil = {
	/**
	 * 获取数据类型列表
	 * @returns {Promise<[]>}
	 */
	async getDataTypes() {
		let data = [];
		let res = await store.dispatch(
			"dict/getDictEntity",
			{}
		);
		let tobaccoQuality = [];
		let tobaccoPlantingEnvironment = [];
		res.data.forEach(item => {
			if (item.type === 'tobacco_quality' && item.levelFlag === 1) {
				tobaccoQuality.push({
					'value': item.value,
					'label': item.name
				})
			}
			if (item.type === 'tobacco_planting_environment' && item.levelFlag === 1) {
				tobaccoPlantingEnvironment.push({
					'value': item.value,
					'label': item.name
				})
			}
		})
		data.push({
			'value': '1',
			'label': '植烟环境',
			'children': tobaccoPlantingEnvironment
		}, {
			'value': '2',
			'label': '烟叶质量',
			'children': tobaccoQuality
		})
		return data;
	},

	/**
	 * 获取年份，2010至今，从2010年开始到现在，默认或false-倒排,true-正排
	 */
	getDateTimes(val) {
		let dateTimes = [];
		let fullYear = new Date().getFullYear();
		for (let i = 2000; i <= fullYear; i++) {
			dateTimes.push({
				value: i,
				label: i + '年'
			})
		}
		if (val) {
			return dateTimes;
		}
		return dateTimes.reverse();
	},


	/**
	 * 获取要删除的id列表
	 * @returns {Promise<*[]>}
	 */
	getSelectList() {
		let list = [];
		switch (type) {
			case "1":
			case "2":
			case "3":
			case "4":
			case "5":
			case "6":
			case "7":
				list = store.state['tobaccoEnvironment'].selectList;
				break;
			case "21":
			case "22":
			case "23":
			case "24":
			case "25":
			case "26":
			case "27":
				list = store.state['tobaccoQuality'].selectList;
				break;
			default:
				break;
		}
		return list;
	},

	/**
	 * 修改单个参数
	 */
	async changeParamsSingle(key, value) {
		switch (type) {
			case "1":
			case "2":
			case "3":
			case "4":
			case "5":
			case "6":
			case "7":
				await store.dispatch('tobaccoEnvironment/changeParamsSingle', {
					'key': key,
					'value': value
				});
				break;
			case "21":
			case "22":
			case "23":
			case "24":
			case "25":
			case "26":
			case "27":
				await store.dispatch('tobaccoQuality/changeParamsSingle', {
					'key': key,
					'value': value
				});
				break;
			default:
				break;
		}
	},

	/**
	 * 下载数据
	 */
	async downloadExcel() {
		// 保存老数据
		let oldList = this.saveData();
		// 更改参数，然后获取新数据
		await this.changeParamsSingle("pageNum", -1);
		await this.search();
		// 下载
		let tableNames = tableName[type] + "-" + new Date().getTime() + '.xlsx';
		let tableId = 'environment';
		switch (type) {
			case '1':
			case '2':
			case '3':
			case '4':
			case '5':
			case '6':
			case '7':
				tableId = 'environment';
				break;
			case '21':
			case '22':
			case '23':
			case '24':
			case '25':
			case '26':
			case '27':
				tableId = 'quality';
				break;
			default:
				break;
		}
		this.exportExcel(tableId, tableNames);
		// 还原老数据
		await this.reductionData(oldList);
	},

	/**
	 * 保存数据
	 */
	saveData() {
		let oldData = [];
		switch (type) {
			case "1":
			case "2":
			case "3":
			case "4":
			case "5":
			case "6":
			case "7":
				oldData = store.state['tobaccoEnvironment'].list;
				break;
			case "21":
			case "22":
			case "23":
			case "24":
			case "25":
			case "26":
			case "27":
				oldData = store.state['tobaccoQuality'].list;
				break;
			default:
				break;
		}
		return oldData;
	},

	/**
	 * 还原数据
	 */
	async reductionData(oldList) {
		switch (type) {
			case "1":
			case "2":
			case "3":
			case "4":
			case "5":
			case "6":
			case "7":
				await store.dispatch("tobaccoEnvironment/changeList", oldList);
				break;
			case "21":
			case "22":
			case "23":
			case "24":
			case "25":
			case "26":
			case "27":
				await store.dispatch("tobaccoQuality/changeList", oldList);
				break;
			default:
				break;
		}
	},

	/**
	 * 查询
	 * @returns {Promise<null>}
	 */
	async search() {
		let res = null;
		switch (type) {
			case "1":
			case "2":
			case "3":
			case "4":
			case "5":
			case "6":
			case "7":
				await store.dispatch('tobaccoEnvironment/changeType', type);
				res = await store.dispatch("tobaccoEnvironment/search", url[type]);
				break;
			case "21":
			case "22":
			case "23":
			case "24":
			case "25":
			case "26":
			case "27":
				await store.dispatch('tobaccoQuality/changeType', type);
				res = await store.dispatch("tobaccoQuality/search", url[type]);
				break;
			default:
				break;
		}
		return res;
	},

	/**
	 *  数据类型更改
	 *  植烟环境--------
	 *  地理环境        1
	 *  气象-降雨量    2
	 *  气象-气温    3
	 *  气象-日照时数    4
	 *  土壤-常规养分    5
	 *  土壤-农残    6
	 *  土壤-重金属    7
	 *  烟叶质量--------
	 *  常规化学成分    21
	 *  外观品质    22
	 *  物理特性    23
	 *  感官评吸    24
	 *  色素多酚    25
	 *  有机酸    26
	 *  中性致香成分  27
	 */

	/**
	 * 切换页面
	 * @param val
	 * @returns {Promise<string>}
	 */
	async getType(val) {
		if (val && val.length > 0) {
			if (val[0] === "1") {
				type = "" + val[1];
			}
			if (val[0] === "2") {
				type = "2" + "" + val[1];
			}
		}
		// 初始化数据
		await store.dispatch("tobaccoQuality/changeType", -999);
		await store.dispatch("tobaccoEnvironment/changeType", -999);
		await store.dispatch("tobaccoQuality/changeList", []);
		await store.dispatch("tobaccoEnvironment/changeList", []);
		await store.dispatch('dataManagement/changeSelect', false);
		await store.dispatch('tobaccoQuality/changeExportFlag', false);
		await store.dispatch('tobaccoEnvironment/changeExportFlag', false);
		return type;
	},

	/**
	 * 获取当前行政区的对象
	 * @param areaCode
	 * @param areaTree
	 */
	getAreaObject(areaCode, areaTree) {
		areaObject = {}
		this.getChildrenList1(areaTree, areaCode);
		return areaObject;
	},

	/**
	 * 根据选中的行政区code列表获取行政树里对应的名称列表
	 * @param checkArea 选中的行政code
	 * @param areaTree 行政树
	 * @returns {*[]}
	 */
	getAreaName(checkArea, areaTree) {
		areaName = [];
		checkArea.forEach(item => {
			this.getChildrenList(areaTree, item);
		})
		return areaName;
	},

	/**
	 * 获取名称
	 * @param val
	 * @param val1
	 */
	getChildrenList(areaTree, code) {
		areaTree.forEach(item => {
			if (item.code === code) {
				areaName.push(item.name);
			}
			if (item?.childrenList?.length > 0) {
				this.getChildrenList(item.childrenList, code);
			}
		})
	},


	/**
	 * 获取行政区对象
	 * @param val
	 * @param val1
	 */
	getChildrenList1(areaTree, code) {
		areaTree.forEach(item => {
			if (item.code === code) {
				areaObject = item;
			}
			if (item?.childrenList?.length > 0) {
				this.getChildrenList1(item.childrenList, code);
			}
		})
	},


	/**
	 * 还原指标列表
	 * @param index
	 * @returns {*[]}
	 */
	reductionIndexList(index) {
		let indexList = [];
		let indexItem = {
			pointer: '',
			radio: '1',
			selEq: '',
			num1: null,
			num2: null,
			num3: null
		};
		if (index.length > 0) {
			index.forEach(item => {
				let temp = JSON.parse(JSON.stringify(indexItem));
				if (item.type === '1') {
					temp.pointer = item.name;
					temp.radio = '1';
					temp.selEq = item.sign;
					temp.num1 = item.value;
					temp.num2 = null;
					temp.num3 = null;
				}
				if (item.type === '2') {
					temp.pointer = item.name;
					temp.radio = '2';
					temp.selEq = '';
					temp.num1 = null;
					temp.num2 = item.value1;
					temp.num3 = item.value2;
				}
				indexList.push(temp);
			})
		} else {
			let temp = JSON.parse(JSON.stringify(indexItem));
			indexList.push(temp);
		}
		return indexList;
	},

	/**
	 * 导出el-table的excel表格
	 * @param tableId
	 * @param name
	 * @returns {any}
	 */
	exportExcel(tableId, name) {
		//执行此方法导出Excel表格
		// 为el-table添加一个id：out-table
		// 当el-table使用了fixed时会导出两次数据，所以要先进行判断
		let wb;
		let fix = document.querySelector('.el-table__fixed');
		let xlsxParam = {raw: true};
		if (fix) {
			// 如果有fixed，先移除，然后再添加上
			wb = XLSX.utils.table_to_book(document.querySelector("#" + tableId).removeChild(fix), xlsxParam);
			document.querySelector("#" + tableId).appendChild(fix);
		} else {
			wb = XLSX.utils.table_to_book(document.querySelector("#" + tableId), xlsxParam);
		}
		let wbout = XLSX.write(wb, {
			bookType: "xlsx",
			bookSST: true,
			type: "array"
		});
		try {
			FileSaver.saveAs(
				new Blob([wbout], {
					type: "application/octet-stream"
				}),
				// 导出的文件名称
				name
			)
		} catch (e) {
			if (typeof console !== "undefined") {
				console.log(e, wbout);
			}
		}
		return wbout;
	},

	/**
	 * 判断是是否是数值型
	 * @param val
	 * @returns {boolean}
	 */
	isNumber(val) {
		let result = /^[-+]?(([0-9]+)([.]([0-9]+))?|([.]([0-9]+))?)$/.test(val);
		return result;
	}

}


